import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addBookList } from '../../store/slice/BooksSlice';
import TopImageSlider from '../../components/TopImageSlider/TopImageSlider'
import BookCard from '../../components/BookCard/BookCard';
import NoticeSlide from '../../components/noticeSlide/NoticeSlide';


export default function Home() {
    const dispatch = useDispatch();
    const [books, setBooks] = useState([]);
    useEffect(() => {
        Handle_Get_All_New_Subject();
    }, []);

    const Handle_Get_All_New_Subject = async () => {
        try {
            const result = await axios.get('https://api.dakshineswarshayaklibrary.org/User/getBooks');

            if (result.data.status === true) {
                setBooks(result.data.existingBook);
                dispatch(addBookList(result.data.existingBook));
            } else {
                alert(result.data.error);
            }
        } catch (error) {
            alert(error);
        }
    }

    return (
        <div className='w-full h-auto' style={{paddingTop:'160px'}}>
            <TopImageSlider />
            <NoticeSlide />

            <div className='w-[90%] m-auto p-12 flex flex-row flex-wrap items-center justify-center gap-[30px] ' style={{ borderRadius: '15px' }}>
                {
                    books.length > 0 ?
                        books.map(data => (
                            <BookCard key={data.id} data={data} />
                        ))
                        :
                        <></>
                }
            </div>
        </div>
    )
}
