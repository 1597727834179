import React, { useState } from 'react';
import './Navbar.css';
import { useSelector } from 'react-redux';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Remove useSelector if not used
import { dashboardTrue, dashboardFalse } from '../../store/slice/DashBoardSlice';
import logo from '../../assets/Libraryicon/logo.png'
import bellIcon from '../../assets/Libraryicon/bell.png'



import facebook from '../../assets/Libraryicon/facebook.png'
import insta from '../../assets/Libraryicon/Instagramlogo.png'
import twitter from '../../assets/Libraryicon/twitter.png'
import linkedin from '../../assets/Libraryicon/linkedin.png'
import youtube from '../../assets/Libraryicon/youtubelogo.png'




export default function Navbar() {

	const whoIsLogin = useSelector((state) => state.auth.user);

	const dispatch = useDispatch();
	const [nav, setNav] = useState(false);
	const [activeLink, setActiveLink] = useState('/');

	const [dropdownOpen, setDropdownOpen] = useState(false);

	const handleLinkClick = (path) => {
		if (path === '/Catalogue') {
			setDropdownOpen(!dropdownOpen);
			setActiveLink(path);
		} else {
			setActiveLink(path);
			dispatch(dashboardFalse());
			setDropdownOpen(false);
		}
	};

	const handleDashboardClick = () => {
		setActiveLink(undefined);
		dispatch(dashboardTrue());
		setDropdownOpen(false);

	};

	const handleNav = () => {
		setNav(!nav);
	};


	const handleOptionClick = () => {
		setDropdownOpen(false); // Dropdown close after option click
	};


	return (
		<header className='header'>
			<div className={`navbar`}>
				<img src={logo} className='logo'></img>
				<div style={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }} className=' bg-slate-500 w-[40%]'>
					<Link to='#'>
						<img src={facebook} style={{ height: '40px' }}></img>
					</Link>
					<Link to='#'>
						<img src={insta} style={{ height: '40px' }}></img>
					</Link>
					<Link to='#'>
						<img src={twitter} style={{ height: '40px' }}></img>
					</Link>
					<Link to='#'>
						<img src={linkedin} style={{ height: '40px' }}></img>
					</Link>
					<Link to='#'>
						<img src={youtube} style={{ height: '40px' }}></img>
					</Link>
				</div>
				<ul className='menu'>
					<li className="navlinkBell">
						<Link to={'/NoticeListPage'}>
							<img src={bellIcon} className='bellIcon'></img>
						</Link>
					</li>
					{!whoIsLogin || whoIsLogin === null ?
						<li className='Dashboard'>
							<Link onClick={handleDashboardClick} to={'/Login'} className='link'>Login</Link>
						</li>
						:
						<>
							<li className='Dashboard'>
								<Link onClick={handleDashboardClick} to={'/Dashboard'} className='link'>Dashboard</Link>
							</li>
						</>
					}
				</ul>
				{/* 
			<div onClick={handleNav} className='menu-icon'>
				{nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
			</div> */}

				{/* <ul className={`mobile-menu ${nav ? 'open' : ''}`}>
				<h1 className='mobile-logo'>Library.</h1>
				<li>
					<Link onClick={() => dispatch(dashboardFalse())} to={'/'}>Home</Link>
				</li>
				<li>
					<Link onClick={() => dispatch(dashboardFalse())} to={'/AllBooks'}>All Books</Link>
				</li>
				<li>
					<Link onClick={() => dispatch(dashboardFalse())} to={'/ImageGallery'}>Image Gallery</Link>
				</li>
				<li>
					<Link onClick={() => dispatch(dashboardFalse())} to={'/NoticeListPage'}>Notice</Link>
				</li>
				<li>
					<Link onClick={() => dispatch(dashboardTrue())} to={'/Dashboard'}>Dashboard</Link>
				</li>
			</ul> */}
			</div>
			<div className='navLinkArea'>
				<ul className='menu'>
					<li className={activeLink ? (activeLink === '/' ? 'navlink active' : 'navlink') : "navlink"}>
						<Link onClick={() => handleLinkClick('/')} to={'/'}>Home</Link>
					</li>
					<li className={activeLink ? (activeLink === '/ImageGallery' ? 'navlink active' : 'navlink') : "navlink"}>
						<Link onClick={() => handleLinkClick('/ImageGallery')} to={'/ImageGallery'}>Gallery</Link>
					</li>
					<li className={activeLink ? (activeLink === '/AllBooks' ? 'navlink active' : 'navlink') : "navlink"}>
						<Link onClick={() => handleLinkClick('/AllBooks')} to={'/AllBooks'}>All Books</Link>
					</li>
					<li className={activeLink ? (activeLink === '/AboutUs' ? 'navlink active' : 'navlink') : "navlink"}>
						<Link onClick={() => handleLinkClick('/AboutUs')} to={'/#'}>About Us</Link>
					</li>
					<li
						className={activeLink ? (activeLink === '/Catalogue' ? 'navlink active' : 'navlink') : "navlink"}
						onClick={() => handleLinkClick('/Catalogue')}
					>
						Catalogue
						{dropdownOpen && (
							<ul className='absolute mt-[750px] ml-[180px] bg-[#ECE2FF]'>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/12hcRv28Y6SYFWWxHfADQIuJPal23qvOC/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Physics
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1KXZpo2pRjHi41yzBwK4F5g_bOcYXdEEt/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Chemistry
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1euq_rnQtYlTp526MxYMSlKVtFqga6P06/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Mathematics
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1bzx35dLHunM5p_LGPYNcCKKyatntQsVb/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Zoology
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1oekaHCjxdcNgDvptPAiiGeaxmersg9wj/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Botany
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/15SkDgos4p5K4lOT4BZifverWLalDGqUx/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Biopass
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1_4mRtns0ibieN5H8xThF7JlYZze0K8LS/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Nutrition
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1qiEHlLJMNREqb96vL-PWWoZWlalhFrfm/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Computer Science
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1xWbXc4_uJrEK1BawVHJ6QxK7560Ie-LI/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Economics
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1YegpsEMphAQ6CKYrrD5x7NjXERngnFL7/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Geography
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1LDjPM1Q49q2LdIeH_1m8hExj4_suos9A/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Philosophy
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1Gwzk1D5HkoSb8cfZLGQ_O_xMTV5baP9l/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Bengali
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1EtML29Husk27MHchyJ7xke4W0oZaIIro/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										History
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1I1r8CTxWBSj51xpOa8ePuwphcV-90vOu/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Political Science
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1PONy5zVKfA57M3-j9-j0DM2Lwn9YOEYa/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										English
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1NB5oZ9dWN1rYE3P5S9mVEfAoz_yRIj8p/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Sociology
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1v6vmlvwoSsaw_eDwKICb9fQkq0FSJKsc/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Education
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1bzx35dLHunM5p_LGPYNcCKKyatntQsVb/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Journalism
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1KFqhT7J5yuSAahsshIVAbMzPTLMvmWAO/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Psychology
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1kJdTsn6EoZuwmPAYxVx_6W0Rn1T3kQcF/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
										Commerce
									</Link>
								</li>
								<li>
									<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1p_MGMhZwhyxcRmfSf8PMXHEHtSqVOZ_sNqHT2pmGyvA/edit?usp=sharing" onClick={handleOptionClick}>
										Business Management
									</Link>
								</li>
							</ul>

						)}
					</li>
					<li className={activeLink ? (activeLink === '/Donate' ? 'navlink active' : 'navlink') : "navlink"}>
						<Link onClick={() => handleLinkClick('/Donate')} to={'/#'}>Donate</Link>
					</li>
					<li className={activeLink ? (activeLink === '/Contact' ? 'navlink active' : 'navlink') : "navlink"}>
						<Link onClick={() => handleLinkClick('/Contact')} to={'/#'}>Contact</Link>
					</li>
				</ul>
			</div>
		</header>

	);
};








// <div style={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px', paddingBottom: '20px' }}>
// 	<Link to='#'>
// 		<img src={facebook} style={{ height: '40px' }}></img>
// 	</Link>
// 	<Link to='#'>
// 		<img src={insta} style={{ height: '40px' }}></img>
// 	</Link>
// 	<Link to='#'>
// 		<img src={twitter} style={{ height: '40px' }}></img>
// 	</Link>
// 	<Link to='#'>
// 		<img src={linkedin} style={{ height: '40px' }}></img>
// 	</Link>
// 	<Link to='#'>
// 		<img src={youtube} style={{ height: '40px' }}></img>
// 	</Link>
// </div>








{/* <ul className='menu'>
				<li className={activeLink ? (activeLink === '/' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/')} to={'/'}>Home</Link>
				</li>
				<li className={activeLink ? (activeLink === '/ImageGallery' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/ImageGallery')} to={'/ImageGallery'}>Gallery</Link>
				</li>
				<li className={activeLink ? (activeLink === '/AllBooks' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/AllBooks')} to={'/AllBooks'}>All Books</Link>
				</li>
				<li className={activeLink ? (activeLink === '/AboutUs' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/AboutUs')} to={'/#'}>About Us</Link>
				</li>
				<li
					className={activeLink ? (activeLink === '/Catalogue' ? 'navlink active' : 'navlink') : "navlink"}
					onClick={() => handleLinkClick('/Catalogue')}
				>
					Catalogue
					{dropdownOpen && (
						<ul className='absolute mt-[750px] ml-[180px] bg-[#ECE2FF]'>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/12hcRv28Y6SYFWWxHfADQIuJPal23qvOC/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Physics
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1KXZpo2pRjHi41yzBwK4F5g_bOcYXdEEt/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Chemistry
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1euq_rnQtYlTp526MxYMSlKVtFqga6P06/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Mathematics
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1bzx35dLHunM5p_LGPYNcCKKyatntQsVb/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Zoology
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1oekaHCjxdcNgDvptPAiiGeaxmersg9wj/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Botany
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/15SkDgos4p5K4lOT4BZifverWLalDGqUx/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Biopass
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1_4mRtns0ibieN5H8xThF7JlYZze0K8LS/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Nutrition
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1qiEHlLJMNREqb96vL-PWWoZWlalhFrfm/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Computer Science
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1xWbXc4_uJrEK1BawVHJ6QxK7560Ie-LI/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Economics
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1YegpsEMphAQ6CKYrrD5x7NjXERngnFL7/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Geography
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1LDjPM1Q49q2LdIeH_1m8hExj4_suos9A/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Philosophy
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1Gwzk1D5HkoSb8cfZLGQ_O_xMTV5baP9l/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Bengali
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1EtML29Husk27MHchyJ7xke4W0oZaIIro/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									History
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1I1r8CTxWBSj51xpOa8ePuwphcV-90vOu/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Political Science
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1PONy5zVKfA57M3-j9-j0DM2Lwn9YOEYa/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									English
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1NB5oZ9dWN1rYE3P5S9mVEfAoz_yRIj8p/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Sociology
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1v6vmlvwoSsaw_eDwKICb9fQkq0FSJKsc/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Education
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1bzx35dLHunM5p_LGPYNcCKKyatntQsVb/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Journalism
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1KFqhT7J5yuSAahsshIVAbMzPTLMvmWAO/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Psychology
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1kJdTsn6EoZuwmPAYxVx_6W0Rn1T3kQcF/edit?usp=sharing&ouid=100050184554668564430&rtpof=true&sd=true" onClick={handleOptionClick}>
									Commerce
								</Link>
							</li>
							<li>
								<Link target='_blank' to="https://docs.google.com/spreadsheets/d/1p_MGMhZwhyxcRmfSf8PMXHEHtSqVOZ_sNqHT2pmGyvA/edit?usp=sharing" onClick={handleOptionClick}>
									Business Management
								</Link>
							</li>
						</ul>

					)}
				</li>
				<li className={activeLink ? (activeLink === '/Donate' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/Donate')} to={'/#'}>Donate</Link>
				</li>
				<li className={activeLink ? (activeLink === '/Contact' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/Contact')} to={'/#'}>Contact</Link>
				</li>
				<li className="navlinkBell">
					<Link to={'/NoticeListPage'}>
					<img src={bellIcon} className='bellIcon'></img>
					</Link>
				</li>
				{!whoIsLogin || whoIsLogin === null ?
					<li className='Dashboard'>
						<Link onClick={handleDashboardClick} to={'/Login'} className='link'>Login</Link>
					</li>
					:
					<>
						<li className='Dashboard'>
							<Link onClick={handleDashboardClick} to={'/Dashboard'} className='link'>Dashboard</Link>
						</li>
					</>
				}
			</ul> */}
