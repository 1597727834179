import React, { useState } from 'react';
import './NoticeSlide.css';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const data = [
    { disc: "Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet " },
    { disc: "Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet " },
    { disc: "Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet " },
    { disc: "Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet " },
    { disc: "Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet " },
];

const NextArrow = ({ onClick }) => {
    return (
        <div className="arrow next" onClick={onClick}>
            <IoIosArrowForward />
        </div>
    );
};

const PrevArrow = ({ onClick }) => {
    return (
        <div className="arrow prev" onClick={onClick}>
            <IoIosArrowBack />
        </div>
    );
};

const NoticeSlide = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const settings = {
        centerMode: true,
        centerPadding: '30px',
        slidesToShow: 1,
        autoplay: false,
        speed: 500,
        arrows: true,
        beforeChange: (current, next) => setSlideIndex(next),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <div className='NoticeSlidePage' >
            <div className='about'>
                <div className="slide slide-active">
                    <h1 className='notice-title'>About Us</h1>
                    <h2 className='notice-heading'>Lorem ipsum dolor sit amet</h2>
                    <p className='notice-date'>Date - 15/6/24</p>
                    <div className='notice-content'>
                        Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet consectetur adipiscing elit. Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet Etiam ullamcorper.Aenean fermentum lacus ut egestas feugiat. Fusce non lacus a sapien Lorem ipsum dolor sit amet
                    </div>
                    <button className='aboutViewBtn'>View</button>
                </div>
            </div>
            <div className='NoticeSlide'>
                <div className='slide slide-active'>
                    <h1 className='notice-title'>Notice</h1>

                    <Slider {...settings}>
                        {data.map((item, index) => (
                            <>
                                <h2 className='notice-heading'>Lorem ipsum dolor sit amet</h2>
                                <p className='notice-date'>Date - 15/6/24</p>
                                <div className='notice-content'>
                                    {item.disc}
                                </div>
                            </>
                        ))}
                    </Slider>
                </div>

            </div>
        </div>
    );
};

export default NoticeSlide;







{/* <Slider {...settings}>
{data.map((item, index) => (
    <div key={index} className={index === slideIndex ? 'slide slide-active' : 'slide'}>
        <h1 className='notice-title'>Notice</h1>
        <h2 className='notice-heading'>Lorem ipsum dolor sit amet</h2>
        <p className='notice-date'>Date - 15/6/24</p>
        <div className='notice-content'>
            {item.disc}
        </div>
    </div>
))}
</Slider> */}





