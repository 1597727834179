import React, { useState, useRef } from 'react';
import './TopImageSlider.css';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slider1Image from '../../assets/backgroundImg/Slider1.jpg';
import slider2Image from '../../assets/backgroundImg/Slider2.jpg';
import slider3Image from '../../assets/backgroundImg/Slider3.jpg';
import slider4Image from '../../assets/backgroundImg/Slider4.jpg';
import slider5Image from '../../assets/backgroundImg/Slider2.jpg';


const data = [slider1Image, slider2Image, slider3Image, slider4Image, slider5Image]; // Example with 4 images

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="arrow next" onClick={onClick} style={{
            display: 'block',
            background: '#8343FF',
            color: '#fff',
            borderRadius: '50%',
            fontSize: '20px',
            fontWeight: 'bold',
            padding: '10px',
            cursor: 'pointer',
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
        }}>
            <MdKeyboardDoubleArrowRight />
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="arrow prev" onClick={onClick} style={{
            display: 'block',
            background: '#8343FF',
            color: '#fff',
            borderRadius: '50%',
            fontSize: '20px',
            fontWeight: 'bold',
            padding: '10px',
            cursor: 'pointer',
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
        }}>
            <MdKeyboardDoubleArrowLeft />
        </div>
    );
};

const TopImageSlider = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const sliderRef = useRef(null); // Reference to the Slider component

    const settings = {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
        autoplay: true,
        speed: 500,
        beforeChange: (current, next) => setSlideIndex(next),
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    // Functions to go to specific slide
    const goToSlide = (index) => {
        sliderRef.current.slickGoTo(index);
        setSlideIndex(index);
    };

    // Page number representation for each slide
    const pageNumbers = [
        [3, 2, 1, 5, 4],
        [4, 3, 2, 1, 5],
        [5, 4, 3, 2, 1],
        [1, 5, 4, 3, 2],
        [2, 1, 5, 4, 3]

    ];

    return (
        <div className='TopImageSlider'>
            <div className='TopImgSlider'>
                <Slider ref={sliderRef} {...settings}>
                    {data.map((item, index) => (
                        <img key={index} src={item} className='TopImageSliderImg' alt={`Slide ${index}`} />
                    ))}
                </Slider>
            </div>
            <div className='h-[10%] w-full mt-4 flex justify-center items-end'>
                <ul className='flex justify-center items-center gap-2 h-[80%]'>
                    {/* Previous Button */}
                    <a
                        href='#'
                        className='h-[40px] w-[100px] mr-8 flex justify-center items-center text-gray-600 bg-gray-200 rounded-full font-semibold'
                        onClick={() => goToSlide((slideIndex - 1 + data.length) % data.length)}
                    >
                        Previous
                    </a>

                    {/* Dynamic Page Numbers */}
                    {pageNumbers[slideIndex].map((number, index) => {
    let size, fontSize, textWeight, backgroundColor, fontColor, border, transformStyle;

    if (index === 2) { // Middle number
        size = 'h-[50px] w-[50px]'; // Center circle
        fontSize = 'text-xl font-bold';
        backgroundColor = 'bg-white'; // Background color white
        fontColor = 'text-black'; // Font color black
        border = 'border border-blue-800'; // 1px solid blue border
        transformStyle = 'translate-x-0'; // Default position
    } else if (index === 1 || index === 3) { // Adjacent numbers
        size = 'h-[40px] w-[40px]'; // Side circles
        fontSize = 'text-lg';
        backgroundColor = 'bg-gray-200';
        fontColor = 'text-gray-500';
        transformStyle = index === 1 ? '-translate-x-2' : 'translate-x-2'; // Shift slightly left or right
    } else { // Outer numbers
        size = 'h-[30px] w-[30px]'; // Outer circles
        fontSize = 'text-md';
        backgroundColor = 'bg-gray-200';
        fontColor = 'text-gray-500';
        transformStyle = index === 0 ? '-translate-x-4' : 'translate-x-4'; // Further shift for outer numbers
    }

    return (
        <a
            key={index}
            href='#'
            className={`${size} ${fontSize} ${backgroundColor || ''} ${fontColor || ''} ${border || ''} flex items-center justify-center rounded-full transition-transform duration-300 ease-in-out ${transformStyle}`}
            onClick={() => goToSlide(number - 1)} // -1 because slide index is 0-based
        >
            {number}
        </a>
    );
})}


                    {/* Next Button */}
                    <a
                        href='#'
                        className='h-[40px] w-[100px] ml-8 flex justify-center items-center text-white bg-[#8343FF] rounded-full font-semibold'
                        onClick={() => goToSlide((slideIndex + 1) % data.length)}
                    >
                        Next
                    </a>
                </ul>
            </div>
        </div>
    );
};

export default TopImageSlider;
