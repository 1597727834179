import React from 'react'
import './BookCard.css'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addBookData } from '../../store/slice/BooksSlice';

export default function BookCard({ data }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const whoIsLogin = useSelector((state) => state.auth.user);

    const goCollectBook = (val) => {
        dispatch(addBookData(val));
        navigate("/BookCollect");
    }

    return (
        <div class="BookCard">
            <img src={`https://api.dakshineswarshayaklibrary.org/uploads/${data.bookIMG}`} />
            <h1>{data.name}</h1>
            <button onClick={() => goCollectBook(data)}>View</button>
        </div>
    )
}











// <div onClick={() => goCollectBook(data)} className="w-64 h-[510px] rounded-lg flex flex-col items-center justify-center bg-white m-2.5">
//             <div className="h-96 w-full flex items-center justify-center">
//                 <img src={`https://api.dakshineswarshayaklibrary.org/uploads/${data.bookIMG}`} className="h-[90%] object-cover rounded-t-lg" />
//             </div>
//             <div className="h-[130px] w-[90%] text-center">
//                 <h1 className="text-xl font-bold">{data.name}</h1>
//                 <p className="text-lg">{data.description}</p>
//                 <p>ID: 1236285</p>
//             </div>
//             </div>