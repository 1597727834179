import { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import './ImageGallery.css'
import leftArrow from '../../assets/left-arrow.png'
import rightArrow from '../../assets/right-arrow.png'
import CustomButton1 from "../../components/CustomButton/CustomButton1";

export default function ImageGallery() {

	const buttonListRef = useRef(null);

	const [galleryImages, setGalleryImages] = useState();
	const [imageList, setImageList] = useState();

	useEffect(() => {
		handleGetGalleryImages();
	}, [])

	const handleGetGalleryImages = async () => {
		try {
			const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/User/getImages`);

			if (result.data.status === true) {
				setGalleryImages(result.data.existingImage);
				setImageList(result.data.existingImage[0]);
			} else {
				alert(result.data.error);
			}
		} catch (error) {
			console.error(error);
			alert("Failed to get images list");
		}
	}

	const [slideNumber, setSlideNumber] = useState(0);
	const [openModal, setOpenModal] = useState(false);

	const handleOpenModal = (index) => {
		setSlideNumber(index);
		setOpenModal(true);
	};

	// Close Modal
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// Previous Image
	const prevSlide = () => {
		slideNumber === 0
			? setSlideNumber(imageList.imageList.length - 1)
			: setSlideNumber(slideNumber - 1);
	};

	// Next Image
	const nextSlide = () => {
		slideNumber + 1 === imageList.imageList.length
			? setSlideNumber(0)
			: setSlideNumber(slideNumber + 1);
	};





	const scroll = (direction) => {
		if (buttonListRef.current) {
			const scrollAmount = direction === 'left' ? -100 : 100; // Adjust the scroll amount as needed
			buttonListRef.current.scrollBy({
				left: scrollAmount,
				behavior: 'smooth',
			});
		}
	};

	return (
		<div className="ImageGallery">
			<div className="topArea">
				<div className="topAboutArea">
					<h1>About Library</h1>
					<p>Welcome to Dakshineswar Shayak Library, a dedicated public library committed to providing access of textbooks for undergraduate and postgraduate students.
						With the sincere support of each and every one of you, Shayak was created to provide some relief to undergraduate and graduate level students and their parents from the hardships of high cost of textbooks. Our aim is to ensure that every student, regardless of financial background, has the opportunity to excel academically without the burden of book costs.
						In this way, the library has been serving the students through various ups and downs. In the near future, we are excited to extend our services to Higher Secondary students, further promoting education and equal opportunities for all.
						<br></br>
						Thanks to the generous support of donors, we are able to sustain and expand this initiative.
						Join us in empowering students and building a brighter, more informed future. Our ability to continue and grow depends on the ongoing financial support of community members like you. By contributing to our cause, you are not only fostering education but also encouraging positive, moral development in society.</p>
				</div>
			</div>
			{
				galleryImages ?
					<div className="galaeryArea">
						<div className="navigationTabBar">
							<button className='page-link' onClick={() => scroll('left')}>
								<img src={leftArrow} alt="Previous" />
							</button>

							<ul className="buttonList" ref={buttonListRef}>
								{
									galleryImages.map((data, index) => (
										<li key={index}>
											<button className="buttonListBtn" onClick={() => setImageList(data)}>{data.category}</button>
										</li>
									))
								}
							</ul>

							<button className='page-link' onClick={() => scroll('right')}>
								<img src={rightArrow} alt="Next" />
							</button>
						</div>
						{
							openModal && (
								<div className="sliderWrap">
									<FontAwesomeIcon
										icon={faCircleXmark}
										className="btnClose"
										onClick={handleCloseModal}
									/>
									<FontAwesomeIcon
										icon={faCircleChevronLeft}
										className="btnPrev"
										onClick={prevSlide}
									/>
									<FontAwesomeIcon
										icon={faCircleChevronRight}
										className="btnNext"
										onClick={nextSlide}
									/>
									<div className="fullScreenImage">
										<img src={`https://api.dakshineswarshayaklibrary.org/uploads/${imageList.imageList[slideNumber]}`} alt="" />
									</div>
								</div>
							)
						}
						<div className="galleryWrap">
							{imageList && imageList.imageList &&
								imageList.imageList.map((slide, index) => {
									return (
										<div
											className="single"
											key={index}
											onClick={() => handleOpenModal(index)}
										>
											<img src={`https://api.dakshineswarshayaklibrary.org/uploads/${slide}`} alt="" />

										</div>
									);
								})}
						</div>
					</div>
					:
					<></>
			}
		</div>
	)
}
