import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';

import logo from '../../assets/Libraryicon/logo.png'
import facebook from '../../assets/Libraryicon/facebook.png'
import insta from '../../assets/Libraryicon/Instagramlogo.png'
import twitter from '../../assets/Libraryicon/twitter.png'
import linkedin from '../../assets/Libraryicon/linkedin.png'
import youtube from '../../assets/Libraryicon/youtubelogo.png'



export default function Footer() {
	return (
		<div className='footerArea'>
			<div class="footerAreaToplibrery">
				<div class="footerAreaTopcontainer">
					<div class="librery-row">
						<div class="li-col-1">
							<img src={logo} class="logo-img" />
						</div>
						<div class="li-col-2">
							<div class="line"></div>
						</div>
						<div class="li-col-3">
							<div class="sociallinks">
								<Link to='#'>
									<img src={facebook}></img>
								</Link>
								<Link to='#'>
									<img src={insta}></img>
								</Link>
								<Link to='#'>
									<img src={twitter}></img>
								</Link>
								<Link to='#'>
									<img src={linkedin}></img>
								</Link>
								<Link to='#'>
									<img src={youtube}></img>
								</Link>

							</div>
						</div>
					</div>
				</div>
			</div>


			<footer>
				<div class="footerAreaBottomcontainer">
					<div class="foot-row">
						<div class="foot-col">
							<div class="foot-content">
								<h3 class="foot-head">INFO</h3>
								<ul class="foot-nav">
									<li><a href="#">About Us</a></li>
									<li><a href="#">Privacy & Policy</a></li>
									<li><a href="#">Terms & Conditions</a></li>

								</ul>
							</div>
						</div>
						<div class="foot-col">
							<div class="foot-content">
								<h3 class="cont-head">CONTACT US</h3>
								<p class="f-pre">+91 7595929232</p>
								<p class="f-pre">+91 8420011218</p>
								<p class="f-pre">dakshineswarshayak1997@gmail.com</p>
							</div>
						</div>
						<div class="foot-col">
							<div class="foot-content">
								<h3 class="foot-head">LINKS</h3>
								<ul class="foot-nav">
									<li><a href="#">Gallery</a></li>
									<li><a href="#">All Books</a></li>
									<li><a href="#">Catalouge</a></li>

								</ul>
							</div>
						</div>
						<div class="foot-col">
							<div class="foot-content">
								<h3 class="cont-head">FIND US</h3>
								<p class="f-pre">11 Nepal Chandra Chatterjee Street,Ariadaha,Kalkata -700057</p>
								<p class="f-pre">Sunday from 09:30 am to 12:30 pm Sat & Tues from 07:30 pm to 09:30 pm</p>

							</div>
						</div>

					</div>
				</div>
			</footer>
		</div>

	)
}
